// @flow

import React from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";
import HotelCarousel from "./images/hotel_carousel/HotelCarousel";
import Schedule from "./Schedule";
import { useStrings } from "./LanguageContext";

import "./Details.css";

function Details() {
  return (
    <div className="DetailsList">
      <h1>Details</h1>
      <hr />
      <Container fluid={true}>
        <Information title="The Date">
          Wednesday, June 24th, 2020
          <br />
          16:00
        </Information>
        <Information title="The Place">
          <Link href="https://senhoradaguia.com/">
            <i class="fas fa-link" /> Senhora da Guia
            <span className="Dot">•</span>
            Cascais, Portugal
          </Link>
          <div>
            The wedding will be held in the garden of a small boutique hotel
            near the southern coast of portugal called Senhora da Guia. On the
            day & night of the wedding, we have reserved a portion of the hotel,
            called Villa Guia, for the event.
          </div>
          <HotelCarousel />
        </Information>
        <Information title="Schedule">
          <Schedule />
        </Information>
        <Information title="Where to stay">
          <Villa />
          <Hotels />
        </Information>
        <Information title="Directions">
          <WeddingMap />
        </Information>
        <Information title="Frequently Asked Questions">
          <FAQ />
        </Information>
      </Container>
    </div>
  );
}

function Villa() {
  return (
    <div>
      <div>
        <strong>Villa</strong>
      </div>
      <p>
        Seeing that most of us will have to travel to attend the wedding, we
        thought it would be nice to have family members and some lucky friends
        stay together. Therefore, we have rented a villa near Cascais, from June
        20th to June 27th.
      </p>
      <Link href="https://moradasol.com/">
        <i class="fas fa-link" /> Morada Sol
      </Link>
      <VillaMap />
    </div>
  );
}

function Hotels() {
  return (
    <div>
      <div>
        <strong>Hotels</strong>
      </div>
      You are of course free to stay anywhere you'd like, but here's a few
      hotels near the wedding you may be interested in.
      <Container>
        <HotelRow>
          <Link href="https://senhoradaguia.com/">Senhora da Guia</Link>
        </HotelRow>
        <HotelRow drive="6 min" walk="44 min">
          <Link href="https://hotelbaia.com/">Hotel Baia</Link>
        </HotelRow>
        <HotelRow drive="7 min" walk="23 min">
          <Link href="https://theoitavos.com/">The Oitavos</Link>
        </HotelRow>
        <HotelRow drive="5 min" walk="22 min">
          <Link href="https://www.pestana.com/en/hotel/pestana-cascais/">
            Pestana Cascais
          </Link>
        </HotelRow>
        <HotelRow drive="31 min">
          <Link href="https://hoteldabaixa.com/">Hotel da Baixa</Link>
        </HotelRow>
        <HotelRow drive="33 min">
          <Link href="https://almalusahotels.com/">Almalus Hotels</Link>
        </HotelRow>
      </Container>
    </div>
  );
}

function HotelRow({ drive, walk, children }) {
  return (
    <Row fluid noGutters className="hotel-row">
      <Col>{children}</Col>
      <Col xs="3">
        <i className="fas fa-car" />
        {drive || <MDash />}
      </Col>
      <Col xs="3">
        <i className="fas fa-walking" />
        {walk || <MDash />}
      </Col>
    </Row>
  );
}

function MDash() {
  return <> &mdash;</>;
}

function FAQ() {
  const strings = useStrings();
  return (
    <Container>
      <Row>
        <Col>
          <FAQItem title={strings.dresscode_label}>{strings.dresscode}</FAQItem>
          <FAQItem title="Is there parking at the venue?">
            Yes. Valet and self park is available to everyone staying the night
            at the hotel. In addition, up to 10 spots for wedding guests staying
            outside of the hotel will be available.
          </FAQItem>
        </Col>
      </Row>
    </Container>
  );
}

function FAQItem({ title, children }) {
  return (
    <p>
      <strong>{title}</strong>
      <br />
      {children}
    </p>
  );
}

function Information({ title, children }) {
  return (
    <Row className="justify-content-center infoRow">
      <Col lg={2} md={4}>
        <div className="Details">{title}</div>
      </Col>
      <Col lg={6} md={8}>
        {children}
      </Col>
    </Row>
  );
}

function WeddingMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d49820.48208507674!2d-9.4523256!3d38.6998945!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x92c2cc604a7a205d!2sSenhora%20da%20Guia%20Cascais%20Boutique%20Hotel!5e0!3m2!1sen!2sus!4v1579562021920!5m2!1sen!2sus"
      width="100%"
      height="325"
      frameborder="0"
      allowfullscreen=""
    />
  );
}

function VillaMap() {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28540.86559741017!2d-9.442626688317718!3d38.728870999927686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ec4980408e7e1%3A0x151147f2d98ba142!2sMorada%20Sol%20-%20Holiday%20Villas%20in%20Cascais!5e0!3m2!1sen!2sus!4v1579562077854!5m2!1sen!2sus"
      width="100%"
      height="325"
      frameborder="0"
      allowfullscreen=""
    />
  );
}

function Link({ href, children }) {
  return (
    <a target="_blank" href={href}>
      {children}
    </a>
  );
}

export default Details;
