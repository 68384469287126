// @flow

const firebaseConfig = {
  apiKey: "AIzaSyCek1C6LhCnhyguWoZYCwukfg4xRoDJIOg",
  authDomain: "wedding-2592c.firebaseapp.com",
  databaseURL: "https://wedding-2592c.firebaseio.com",
  projectId: "wedding-2592c",
  storageBucket: "wedding-2592c.appspot.com",
  messagingSenderId: "1057852008217",
  appId: "1:1057852008217:web:75693dcce12b9d1c3e4788",
  measurementId: "G-T20V6QYXTX"
};

export default firebaseConfig;
