// @flow

import React from "react";
import { Form, Button, ListGroup, Container, Row, Col } from "react-bootstrap";
import Backend from "./backend/Backend";

export default function RSVPList() {
  const [user, isLoading, error] = Backend.useAuth();

  return user == null ? <Login /> : <List />;
}

function Login() {
  const onSubmit = event => {
    console.log({ event });
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      return;
    }
    Backend.login(
      form.elements.namedItem("email").value,
      form.elements.namedItem("password").value
    );
  };
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group controlId="email">
        <Form.Control name="email" type="email" placeholder="E-Mail" />
      </Form.Group>
      <Form.Group controlId="password">
        <Form.Control name="password" type="password" placeholder="Password" />
      </Form.Group>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
  );
}

function List() {
  const [list, loading, error] = Backend.useRSVPList();

  return (
    <ListGroup>
      {list.map((item, index) => {
        const val = item.val();
        console.log(val);
        return (
          <ListGroup.Item key={index}>
            <Container>
              <Entry label="Time">{new Date(val.timestamp).toString()}</Entry>
              <Entry label="Code">{val.code}</Entry>
              <Entry label="Can they make it?">{val.canYouMakeIt}</Entry>
              <Entry label="E-Mail">{val.email}</Entry>
              <Entry label="Stay at villa">{val.villa}</Entry>
              <Entry label="Dietary Requirements">{val.diet}</Entry>
              <Entry label="Message">{val.message}</Entry>
              <Entry label="Guests">
                <ListGroup>
                  {val.guests.map((name, index) => (
                    <ListGroup.Item key={index}>{name}</ListGroup.Item>
                  ))}
                </ListGroup>
              </Entry>
            </Container>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

function Entry({ label, children }) {
  return (
    <Row>
      <Col md="4" sm="12">
        <strong>{label}</strong>
      </Col>
      <Col md="8" sm="12">
        {children}
      </Col>
    </Row>
  );
}
