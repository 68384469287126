// @flow

import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Modal,
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  Image,
  Navbar,
  Nav,
  NavDropdown
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import RSVPForm from "./RSVPForm";
import Details from "./Details";
import Registry from "./Registry";
import LocalizedStrings from "react-localization";
import LanguageContext from "./LanguageContext";
import { useStrings } from "./LanguageContext";
import usFlag from "./images/us.png";
import brFlag from "./images/brazil.png";
import PersonalImageGrid from "./images/personal/PersonalImageGrid";
import RSVPList from "./RSVPList";

const { useState } = React;

const defaultLanguage =
  window.navigator.language.slice(0, 2) === "pt" ? "pt" : "en";

function App() {
  const [language, setLanguage] = React.useState(defaultLanguage);

  return (
    <div className="App">
      <LanguageContext.Provider value={language}>
        <Navigation language={language} setLanguage={setLanguage} />
        <Router>
          <Route exact path="/" component={Home} />
          <Route path="/rsvp" component={RSVPForm} />
          <Route path="/details" component={Details} />
          <Route path="/registry" component={Registry} />
          <Route path="/presentes" component={Registry} />
          <Route path="/rsvp_list" component={RSVPList} />
        </Router>
      </LanguageContext.Provider>
    </div>
  );
}

function Flag({ locale }) {
  const img = locale === "pt" ? brFlag : usFlag;
  return <Image style={{ width: 24, height: 24 }} src={img} fluid />;
}

function Navigation({ language, setLanguage }) {
  const strings = useStrings();

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Navbar.Brand href="/" className="fancy-text">
        Magne & Natália
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/rsvp">{strings.rsvp}</Nav.Link>
          <Nav.Link href="/details">Details</Nav.Link>
          <Nav.Link href="/registry">{strings.registry}</Nav.Link>
          <NavDropdown
            title={<Flag locale={language} />}
            id="collasible-nav-dropdown"
            onSelect={setLanguage}
          >
            <NavDropdown.Item eventKey="us">
              <Flag locale="us" /> English
            </NavDropdown.Item>
            <NavDropdown.Item eventKey="pt">
              <Flag locale="pt" /> Portuguese
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

function Home() {
  return (
    <div>
      <Header />
      <PostponeMessage />
      <Hero />
      <hr />
      <div className="p-tile">
        <OurStory />
      </div>
      <PersonalImageGrid />
    </div>
  );
}

function Hero() {
  const strings = useStrings();
  return (
    <div className="Hero">
      <div className="Container">
        <LinkContainer to="/rsvp">
          <Button variant="outline-dark" size="lg" block>
            {strings.rsvp}
          </Button>
        </LinkContainer>
      </div>
    </div>
  );
}

function PostponeMessage() {
  const [show, setShow] = useState(true);
  const onHide = () => setShow(false);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Wedding canceled due to Coronavirus
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Due to our current circumstances, the previously planned wedding is
          effectively canceled. We will still try to hold a smaller ceremony
          later in the year for family only. We're thankful for all our friends
          that wanted to come join us to celebrate, but due to covid it ended up
          being too difficult to organize a larger wedding event. We appreciate
          your patience and understanding.
        </p>
      </Modal.Body>
    </Modal>
  );
}

function Header() {
  // const timeToWedding = new Date("2020-06-24").getTime() - Date.now();
  // const daysToWedding = Math.floor(timeToWedding / (1000 * 60 * 60 * 24));
  //
  // let dayCounter;
  // if (daysToWedding > 1) {
  //   dayCounter = (
  //     <div className="Details">Only {daysToWedding} days to go!</div>
  //   );
  // } else if (daysToWedding === 1) {
  //   dayCounter = <div className="Details">Tomorrow!</div>;
  // } else if (daysToWedding === 0) {
  //   dayCounter = <div className="Details">Today!</div>;
  // }

  return (
    <div className="Header">
      <h1>Magne & Natália</h1>
      <div className="fancy-text hover-again">are getting married!</div>
      <hr />
      <Container className="Details text-uppercase" fluid={true}>
        <Row className="justify-content-center" noGutters={true}>
          <Col sm="auto" xs={8}>
            November, 2021
          </Col>
          <Col sm="auto" className="d-none d-sm-block">
            <span className="Dot">•</span>
          </Col>
          <Col sm="auto" xs={8}>
            Cascais, Portugal
          </Col>
        </Row>
        {/*
        <Row className="justify-content-center">
          <Col>{dayCounter}</Col>
        </Row>
      */}
      </Container>
    </div>
  );
}

function OurStory() {
  return (
    <>
      <h2>Our Story</h2>
      <div className="mx-auto w-75">
        <p>
          On a Tuesday 5 years ago, Natália and Magne matched online. That same
          day, they went on a first date. They both hit it off right away, and
          Magne quickly found out how protective Natália’s parents were of her.
          Natália was just getting off work and sent her parents a message
          saying she would be home later that day, to which she got a response:
          “Natália!😡”, and that seemed to amuse Magne.
        </p>
        <p>
          They saw each other a few more times during Magne’s visit, and even
          traveled to São Paulo (for a day) together! But then, they both left
          the country. They kept talking almost daily over the internet, until
          Magne went back to Brazil the next year. Less than a month after he
          left, they met in New York, where they officially started dating
          (finally)! For Christmas that same year, Natália went to Norway for
          the first time to meet Magne’s family and for her first white
          Christmas ever!
        </p>
        <p>
          After Norway, they travelled back to California, where Magne was
          living at the time, and they had a lot of fun. During that trip, they
          saw some posters for a university in Downtown SF and joked that maybe
          Natália should transfer there. After Natália got back to Rio, they
          noticed that they could not be away from each other anymore, and after
          some debate and hypotheses, they decided that Natália would move to
          the Bay Area with Magne, and start a new school (which turned out to
          be that same one they had seen posters of!).
        </p>
        <p>
          In July, 2017 (exactly 2 years after they met), they moved in
          together. A few months later, they got Lexa and the rest is history.
          Getting married in Portugal has a lot of practical and sentimental
          value, which is perfect for them, since Magne is practical and
          Natália, sentimental! We can’t wait to see you there!
        </p>
      </div>
    </>
  );
}

export default App;
