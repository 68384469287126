// @flow
import React from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Container,
  Row,
  Col,
  ProgressBar,
  Spinner,
  InputGroup,
  Image
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Fade from "react-reveal/Fade";
import Backend from "./backend/Backend";
import "./RSVPForm.css";
import { useStrings } from "./LanguageContext";

const { useState, useRef, useEffect, useCallback } = React;

function useInvitationCode() {
  const [text, setInvitationCode] = useState("");
  const invitationCodeFieldRef = useRef();
  const [capabilities, isLoading, error] = Backend.useCode(text);

  function onChange(event) {
    const value = event.target.value;
    invitationCodeFieldRef.current = event.target;
    setInvitationCode(value);
  }

  return {
    text,
    isValid: capabilities != null,
    capabilities,
    isLoading,
    error,
    onChange
  };
}

type SubmitStatus = "none" | "pending" | "done" | "error";

function delay(timeout): Promise<void> {
  return new Promise(resolve => setTimeout(() => resolve(), timeout));
}

function useSubmit(): [() => void, SubmitStatus] {
  const [status, setStatus] = useState("none");
  const history = useHistory();

  const handleSubmit = useCallback(
    event => {
      event.preventDefault();
      event.stopPropagation();

      const form = event.currentTarget;
      const elements = form.elements;
      if (
        form.checkValidity() === false ||
        ["pending", "done"].includes(status)
      ) {
        return;
      }

      const data = {
        code: elements.namedItem("code").value,
        canYouMakeIt: elements.namedItem("canYouMakeIt").value,
        email: elements.namedItem("email").value,
        message: elements.namedItem("message").value,
        diet: elements.namedItem("diet").value,
        villa: elements.namedItem("villa") && elements.namedItem("villa").value
      };

      const guests = [];
      for (let i = 0; i < elements.length; i++) {
        const item = elements.item(i);
        if (item.name.startsWith("name")) {
          guests.push(item.value);
        }
      }

      setStatus("pending");
      Backend.RSVP({ ...data, guests })
        .catch(() => setStatus("error"))
        .then(() => {
          setStatus("done");
          return delay(2000);
        })
        .then(() => history.push("/"));
    },
    [status]
  );

  return [handleSubmit, status];
}

function RSVPForm() {
  const invitationCode = useInvitationCode();
  const [onSubmit, status] = useSubmit();
  const strings = useStrings();

  return (
    <div style={{ margin: 16 }}>
      <Form onSubmit={onSubmit}>
        <Form.Group controlId="invitationCode">
          <Form.Label>{strings.invitation_code}</Form.Label>
          <Form.Text className="text-muted">
            {strings.invitation_code_desc}
          </Form.Text>
          <Form.Control
            name="code"
            type="text"
            placeholder="Enter code"
            autoCapitalize="none"
            isValid={invitationCode.isValid}
            disabled={invitationCode.isValid}
            value={invitationCode.text}
            onChange={invitationCode.onChange}
          />
        </Form.Group>
        <DetailsForm
          capabilities={invitationCode.capabilities}
          status={status}
        />
      </Form>
    </div>
  );
}

function DetailsForm({ capabilities, status }) {
  const strings = useStrings();
  if (capabilities == null) {
    return null;
  }

  return (
    <Fade>
      <Form.Group controlId="canYouMakeIt">
        <Form.Row>
          <Col xs="6">
            <Form.Label>{strings.can_you_make_it}</Form.Label>
          </Col>
          <Col xs="3" className="d-flex align-items-center">
            <Form.Check
              id="canMakeItYes"
              required
              inline
              name="canYouMakeIt"
              label={strings.yes}
              value="Yes"
              type="radio"
            />
          </Col>
          <Col xs="3" className="d-flex align-items-center">
            <Form.Check
              required
              id="canMakeitNo"
              inline
              name="canYouMakeIt"
              label={strings.no}
              value="No"
              type="radio"
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Group controlId="email">
        <Form.Label>E-Mail</Form.Label>
        <Form.Control
          required
          name="email"
          type="email"
          placeholder="Example: myname@gmail.com"
        />
      </Form.Group>
      <NamesInput capabilities={capabilities} />
      <Form.Group controlId="diet">
        <Form.Label>{strings.diet}</Form.Label>
        <Form.Text className="text-muted">{strings.diet_desc}</Form.Text>
        <Form.Control
          name="diet"
          type="text"
          placeholder="Example: Vegetarian"
        />
      </Form.Group>
      <VillaInfo capabilities={capabilities} />
      <MessageCouple />
      <SubmitButton status={status} />
    </Fade>
  );
}

function NameInput({ id, onAdd, onRemove }) {
  return (
    <div className="spaced">
      <InputGroup>
        <Form.Control required name={"name-" + id} placeholder="Full name" />
        {onAdd == null ? null : (
          <InputGroup.Append>
            <Button variant="outline-success" onClick={() => onAdd(id)}>
              <i className="fas fa-plus"></i>
            </Button>
          </InputGroup.Append>
        )}
        {onRemove == null ? null : (
          <InputGroup.Append>
            <Button variant="outline-danger" onClick={() => onRemove(id)}>
              <i className="fas fa-minus"></i>
            </Button>
          </InputGroup.Append>
        )}
      </InputGroup>
    </div>
  );
}

function NamesInput({ capabilities }) {
  const strings = useStrings();
  const [peopleKeys, setPeopleKeys] = useState([1]);

  const nameInputs = peopleKeys.map(id => (
    <NameInput
      key={id}
      id={id}
      onAdd={
        capabilities.invitation_limit <= peopleKeys.length ||
        peopleKeys[peopleKeys.length - 1] != id
          ? null
          : key =>
              setPeopleKeys(list => [...list, (list[list.length - 1] || 0) + 1])
      }
      onRemove={
        peopleKeys <= 1
          ? null
          : key => setPeopleKeys(list => list.filter(item => item != key))
      }
    />
  ));

  return (
    <Form.Group controlId="name">
      <Form.Label>
        {capabilities.invitation_limit > 1 ? strings.names : strings.name}
      </Form.Label>
      <Form.Text className="text-muted">{strings.name_desc}</Form.Text>
      {nameInputs}
    </Form.Group>
  );
}

function VillaInfo({ capabilities }) {
  const strings = useStrings();
  if (!capabilities.villa_info) {
    return null;
  }

  return (
    <Form.Group className="FormVillaCheck" controlId="villa">
      <Form.Label>Villa</Form.Label>
      <Form.Text>{strings.villa_desc}</Form.Text>
      <Form.Check
        defaultChecked
        id="villa1"
        name="villa"
        label={strings.villa_not_sure}
        value="not-sure"
        type="radio"
      />
      <Form.Check
        id="villa2"
        name="villa"
        label={strings.villa_yes}
        value="yes"
        type="radio"
      />
      <Form.Check
        id="villa3"
        name="villa"
        label={strings.villa_no}
        value="no"
        type="radio"
      />
    </Form.Group>
  );
}

function MessageCouple() {
  return (
    <Form.Group controlId="message">
      <Form.Label>Leave a message</Form.Label>
      <Form.Text>
        Have a question, or do you just want to leave a message for us? Put it
        here!
      </Form.Text>
      <Form.Control
        name="message"
        type="text"
        placeholder="Enter a message here"
        autoCapitalize="none"
      />
    </Form.Group>
  );
}

function SubmitButton({ status }) {
  const strings = useStrings();
  const disabled = !(status === "none" || status === "error");
  let content;
  let variant;
  if (status === "none") {
    content = strings.rsvp;
    variant = "primary";
  } else if (status === "error") {
    content = "There was an error :(";
    variant = "danger";
  } else if (status === "pending") {
    content = (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    );
    variant = "primary";
  } else if (status === "done") {
    content = "Done!";
    variant = "success";
  }

  return (
    <Button type="submit" block size="lg" disabled={disabled}>
      {content}
    </Button>
  );
}

export default RSVPForm;
