// @flow

import React from "react";

import LocalizedStrings from "react-localization";

const defaultLanguage = window.navigator.language.slice(0, 2);
const LanguageContext = React.createContext(
  defaultLanguage === "pt" ? "pt" : "en"
);

export const strings = new LocalizedStrings({
  en: {
    registry: "Registry",
    invitation_code: "Invitation code",
    invitation_code_desc:
      "(This should've been sent to you by Magne and Natália)",
    can_you_make_it: "Can you make it?",
    name: "Name",
    names: "Names",
    names_desc: "(We're curious about who's coming)",
    diet: "Dietary restrictions",
    diet_desc: "(Is there anything the guests in your party can't eat?)",
    villa_desc:
      "Seeing that most of us will have to travel to attend the wedding, we thought it would be nice to have family members and some lucky friends stay together. Therefore, we have rented a villa near Cascais, from June 20th to June 27th. There are two houses within the site and an indoors pool too. One of the houses will be mostly for Brazilians, and the other, for Norwegians. The total cost of the villa is €6736 euros for the whole stay, which means about 240 euros per person if 28 people stay there, including us. In reais, this is a total of R$ 1187,00 (R$ 169.50/night) per person. In kroner, this is 2502 (358/night) per person. We’d love to have you there with us.",
    villa_not_sure: "We're not sure yet.",
    villa_yes: "We will stay with you.",
    villa_no: "We will stay somewhere else.",
    yes: "Yes",
    no: "No",
    rsvp: "RSVP",
    dresscode_label: "What is the dresscode?",
    dresscode: "Cocktail attire."
  },
  pt: {
    registry: "Presentes",
    invitation_code: "Código do convite",
    invitation_code_desc:
      "(Que foi mandado para vocês pelo Magne e pela Natália)",
    can_you_make_it: "Você vai conseguir ir?",
    name: "Nome",
    names: "Nomes",
    names_desc: "(Estamos curiosos sobre quem vai)",
    diet: "Alguma restrição alimentar?",
    diet_desc: "(Tem algo que alguém da sua família não pode comer?)",
    villa_desc:
      "Já que quase todos vão ter que viajar para poder ir ao casamento, nós pensamos que seria legal ter familiares e alguns amigos juntos. Por isso, nós alugamos uma vila perto de Cascais de 20 de junho a 27 de junho. Nela, há duas casas em uma piscina coberta. Uma das casas será mais para os brasileiros, e a outra, para os noruegueses. O custo total da vila é de €6736 euros para a estadia completa, o que significa que serão aproximadamente 240 euros por pessoa se 28 pessoas ficarem lá, incluindo nós dois. Em reais, o total será de R$ 1187,00 (R$ 169.50/noite) por pessoa. Em kroner, será 2502 (358/noite) por pessoa. Nós vamos adorar tê-los conosco!",
    villa_not_sure: "Ainda não decidimos.",
    villa_yes: "Nós vamos ficar com vocês.",
    villa_no: "Nós vamos ficar em outro lugar.",
    yes: "Sim",
    no: "Não",
    rsvp: "Confirmação de Presença",
    dresscode_label: "Traje:",
    dresscode: "Esporte fino"
  }
});

export default LanguageContext;

export function useStrings() {
  const language = React.useContext(LanguageContext);
  if (strings.getLanguage() !== language) {
    strings.setLanguage(language);
  }
  return strings;
}
