// @flow

import React from "react";
import { Container, Row, Col, Image, Modal } from "react-bootstrap";
import "./PersonalImageGrid.css";

import mn1 from "./mn1.jpeg";
import mn2 from "./mn2.jpeg";
import mn3 from "./mn3.jpeg";
import mn4 from "./mn4.jpeg";
import mn5 from "./mn5.jpeg";
import mn6 from "./mn6.jpeg";
import mn7 from "./mn7.jpeg";
import mn8 from "./mn8.jpeg";
import mn9 from "./mn9.jpeg";
import mn10 from "./mn10.jpeg";
import mn11 from "./mn11.jpeg";
import mn12 from "./mn12.jpeg";
import mn13 from "./mn13.jpeg";
import mn14 from "./mn14.jpeg";
import mn15 from "./mn15.jpeg";
import mn16 from "./mn16.jpeg";
import mn17 from "./mn17.jpeg";
import mn18 from "./mn18.jpeg";
import mn19 from "./mn19.jpeg";
import mn20 from "./mn20.jpeg";
import mn21 from "./mn21.jpeg";
import mn22 from "./mn22.jpeg";
import mn23 from "./mn23.jpeg";
import mn24 from "./mn24.jpeg";
import mn25 from "./mn25.jpeg";

export default function PersonalImageGrid() {
  return (
    <Container className="w-75">
      <hr />
      <h2>Memories</h2>
      <Row>
        <Col>
          <ImageBox
            src={mn1}
            tooltip="Our first picture together! August 2015"
          />
          <ImageBox
            src={mn3}
            tooltip="Our first semi-international trip together, New York, August 2016"
          />
        </Col>
        <Col>
          <ImageBox
            src={mn2}
            tooltip="This was the 2nd time Magne went to Rio, July 2016"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn4}
            tooltip="Natália's first time in Norway! December 2016"
          />
        </Col>
        <Col>
          <ImageBox src={mn17} tooltip="Christmas 2016" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox src={mn15} tooltip="New Years 2016" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn5}
            tooltip="Magne and Lilo getting along, March 2017"
          />
        </Col>
        <Col>
          <ImageBox src={mn7} tooltip="Rio March 2017" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn8}
            tooltip="Natália's first Facebook Summer party, July 2017"
            height={245}
          />
          <ImageBox src={mn6} tooltip="Yosemite, September 2017" />
        </Col>
        <Col>
          <ImageBox
            src={mn9}
            tooltip="First night in our first apartment! August 2017"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn11}
            tooltip="First time meeting Lexa! September 2017"
          />
        </Col>
        <Col>
          <ImageBox src={mn13} tooltip="Hawaii, March 2018" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox src={mn14} tooltip="Hawaii, March 2018" />
        </Col>
        <Col>
          <ImageBox src={mn18} tooltip="Toronto, July 2018" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox src={mn20} tooltip="Toronto, July 2018" />
        </Col>
        <Col>
          <ImageBox src={mn19} tooltip="New Year’s in London, 2018-2019" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox src={mn21} tooltip="San Francisco, June 2019" />
        </Col>
        <Col>
          <ImageBox src={mn23} tooltip="We got engaged! February 2019" />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn12}
            tooltip="First time camping together, Norway, August 2019"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ImageBox
            src={mn22}
            tooltip="The fam is back together! August 2019"
            height={910}
          />
        </Col>
        <Col>
          <ImageBox src={mn24} tooltip="Rio, January 2020" />
          <ImageBox
            src={mn25}
            tooltip="Our go-to ice cream place in SF! February 2020"
          />
        </Col>
      </Row>
    </Container>
  );
}

function ImageBox({ src, tooltip, height }) {
  const [show, setVisible] = React.useState(false);

  return (
    <div className="image-box">
      <Image
        src={src}
        fluid
        rounded
        style={height && { height }}
        onClick={() => setVisible(true)}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => setVisible(false)}
      >
        <Modal.Body className="mx-auto">
          <Image src={src} fluid rounded />
          <hr />
          {tooltip}
        </Modal.Body>
      </Modal>
    </div>
  );
}
