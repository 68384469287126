// @flow

import firebaseConfig from "./firebaseConfig";
import Firebase from "firebase";
import { useObjectVal, useList } from "react-firebase-hooks/database";
import { useAuthState } from "react-firebase-hooks/auth";

Firebase.initializeApp(firebaseConfig);
const Database = Firebase.database();

function RSVP(details: {
  guests: Array<string>,
  email: string,
  canYouMakeIt: string
}): Promise<void> {
  console.log(details);
  return Database.ref("rsvp/").push({
    ...details,
    timestamp: Firebase.database.ServerValue.TIMESTAMP
  });
}

export type Capabilities = $ReadOnly<{|
  invitation_limit: number,
  villa_info: boolean
|}>;

function useCode(code: string): [?Capabilities, boolean, ?Error] {
  return useObjectVal(Database.ref("codes/" + code));
}

function useRSVPList(): any {
  return useList(Database.ref("/rsvp").orderByChild("timestamp"));
}

function useAuth(): any {
  return useAuthState(Firebase.auth());
}

function login(email: string, password: string): void {
  Firebase.auth().signInWithEmailAndPassword(email, password);
}

const Backend = {
  RSVP,
  useCode,
  useRSVPList,
  useAuth,
  login
};

export default Backend;
