// @flow

import React from "react";
import { Container, Row, Col, Carousel, Image } from "react-bootstrap";
import "./Schedule.css";

export default function Schedule() {
  const list = [
    ["Guests Arrival, Welcome Drink", "15:45 – 16:00"],
    ["Wedding Ceremony", "16:00 – 16:30"],
    ["Ceremony Toast", "16:30 – 17:00"],
    ["Cocktail and Wedding Photos", "17:00 – 19:00"],
    ["Entrance to the Restaurant, Dinner, Speeches", "19:00 – 22:00"],
    ["Coffee and Digestive", "22:00 – 22:30"],
    ["Cake Cutting Ceremony and Toast", "22:30 – 23:00"],
    ["Dance and Open Bar", "23:00 – 02:00"],
    ["The End", "02:00"]
  ];

  return (
    <Container className="fancy-text">
      {list.map(([event, time], index) => (
        <Row noGutters key={index}>
          <Col className="text-left border-right pb-2 pr-4" xs="8">
            {event}
          </Col>
          <Col className="text-center" xs="4">
            {time}
          </Col>
        </Row>
      ))}
    </Container>
  );
}
