// @flow

import React from "react";
import { Carousel, Image, Tooltip, OverlayTrigger } from "react-bootstrap";

import img0 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-MAIN-BUILDING-BY-NIGHT-.jpg";
import img1 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-POOL.jpg";
import img2 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-VILA-GUIA-.jpg";
import img3 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-VILA-GUIA-BY-NIGHT.jpg";
import img4 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-BREAKFAST-2.jpg";
import img5 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-BREAKFAST.jpg";
import img6 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-BATHROOM-OF-SUPERIOR-LUXURY-SEA-VIEW-ROOM.jpg";
import img7 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-SUPERIOR-LUXURY-SEA-VIEW.jpg";
import img8 from "./DESTINATION-WEDDINGS-IN-PORTUGAL-LUXURY-SUPERIOR-SEA-VIEW-ROOM.jpg";

function ImageCarousel({ images }) {
  return (
    <Carousel>
      {images.map((imageSrc, index) => (
        <Carousel.Item key={index}>
          <Image src={imageSrc} fluid />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

export default function HotelCarousel() {
  return (
    <ImageCarousel
      images={[img0, img1, img2, img3, img4, img5, img6, img7, img8]}
    />
  );
}
